body {
	content: 'Hello main.scss';
}

@import './utils/_reset';
@import './utils/_vars';
@import './utils/_tools';
@import './utils/_util';
@import './utils/_type';


@import './components/site';
@import './components/ui';
@import './components/cont';
@import './components/scrollbar';
@import './components/slider';
@import './components/overlay';