/////////////////////////////////////////
// .mast - Masthead block
/////////////////////////////////////////
.mast {
	position: relative;
	width: 100%;

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 0;
		padding-bottom: calcRatio(17, 6);
	}

	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.important {
	width: 100%;
	color: $col-pink;
	text-align: center;
	background: $col-white;
	padding: 10px 0;
	text-transform: uppercase;

	font-size: 1.25rem;

	@include bp(md) {
		padding: 10px 0;
	}
}

.copy {
	position: relative;
	max-width: 700px;
	margin: 0 auto;
	padding: 1rem $cont_pad-mob;
	text-align: center;
	color: $col-white;

	@include bp(md) {
		padding: 1rem $cont_pad-desk;
	}

	img {
		display: inline-block;
		margin: 0 auto 1rem auto;
	}

	strong {
		font-weight: normal;
		font-size: 1.2rem;

		@include bp(md) {
			font-size: 2rem;
		}
	}

	&__title {
		text-transform: uppercase;
	}

	&__bookend {

		width: 200px;
		position: absolute;
		top: 50px;
		right: 100%;

		&:nth-child(1) {
			transform: scaleX(-1);
		}

		&:nth-child(2) {
			right: auto;
			left: 100%;
		}

		@include bp(md, max) {
			display: none;
		}


	}
}

/////////////////////////////////////////
// .grid - grid layout for Packs
/////////////////////////////////////////
.grid {
	$bp-for-2col: 'sm';

	@extend %u-stripList;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	max-width: $cont-maxW;
	margin: 0 auto 2rem 0;

	@include bp($bp-for-2col) {
		flex-direction: row;
		flex-wrap: wrap;
		align-items: stretch;
		align-content: stretch;
		width: auto;
		margin-right: -$site_pad-mob/2;
		margin-left: -$site_pad-mob/2;

		&--collapse {
			display: inline-flex;
		}
	}



	>li {
		width: 100%;

		@include bp($bp-for-2col) {
			width: 50%;
			padding-left: $site_pad-mob/2;
			padding-right: $site_pad-mob/2;
			padding-bottom: $site_pad-mob;
		}
	}
}

/////////////////////////////////////////
// .pack - pack item
/////////////////////////////////////////
.pack {
	display: flex;
	flex-direction: column;
	justify-self: start;
	align-items: flex-start;
	width: 100%;
	height: 100%;
	padding-bottom: $site_pad-mob;

	@include bp(md) {
		padding: $site_pad-desk;

	}

	&__hero {
		width: calc(100% - 5px);
		margin-top: 0.5rem;
		margin-bottom: 1rem;
		border: 4px solid #322e7a;
		box-shadow: 5px 5px 0 0 $col-black;

		&--vid {

			position: relative;

			&::before {
				content: '';
				display: block;
				width: 100%;
				height: 0;
				padding-bottom: calcRatio(850, 478);
			}

			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	&__title {
		margin-top: 0;
		margin-bottom: 0.75rem;
		font-size: 1.5rem;
		text-transform: uppercase;
		width: 100%;
		text-align: center;
		color: $col-white;
	}

	&__name {
		font-size: 1rem;
		line-height: 1;
		text-transform: uppercase;
		width: 100%;
		padding: 5px 20px;
		background-color: $col-blue;
		background-image: url('../assets/img/grad_blue-lrg.png');
		background-size: auto;
		margin: 0;
		color: $col-white;
	}

	&__ctas {
		@extend %u-stripList;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 0 $site_pad-mob;

		margin: auto 0 0 0;

		@include bp(md) {
			padding: 0 $site_pad-desk;
		}

		li {
			&:nth-child(2) {
				margin: 0 0 0 auto;
			}
		}
	}

	&__descrip {
		// font-size: 2rem;
	}

	ul {
		li {
			margin: 0.5rem 0;
		}
	}
}

.folders {
	position: fixed;
	right: 10px;
	top: 10px;
	display: block;

	@include bp(1410px, max) {
		display: none;
		;
	}

	@include bp(1555px) {
		right: 30px;
		top: 30px;
	}

	img {

		display: block;
		width: 70px;
		cursor: pointer;

		@include bp(1555px, max) {
			width: 50px;
		}
	}

}

.logosSet {
	margin: 0 auto;
	border-collapse: collapse;

	td {
		text-align: center;
		padding: 0 10px;
	}
}

.donationDest {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;

	a {
		margin-top: auto;
		margin-bottom: 0;
	}

	.grid & {
		padding-bottom: 3rem;
	}
}