/////////////////////////////////////////
// Strips a <ol> or <ul> of list default styleing
/////////////////////////////////////////
.u-stripList,
%u-stripList {
	list-style: none;
	margin: 0;
	padding: 0;
}

.is-dNone {
	display: none !important;
}

/////////////////////////////////////////
// .btn - Generic btn styles
/////////////////////////////////////////
.btn {
	display: inline-block;
	text-decoration: inherit;
	cursor: pointer;
	color: inherit;

	// Btn is disabled or child of disabled
	&.is-disabled,
	.is-disabled & {
		pointer-events: none;
		cursor: none;
	}
}