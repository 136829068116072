// Impor tplugin dependency styles
@import '~swiper/swiper.scss';

.swiper-slide {
	img {
		width: 100%;
		display: block;
	}
}

//:::::::::::::::::::::::::::::::::::::::
// .packGal - layout for preview slider gallery
//:::::::::::::::::::::::::::::::::::::::
.packGal {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;

	&__gal {
		width: 100%;
		border: 5px solid #322e78;
		-webkit-box-shadow: 4px 4px 0 0 #000000;
		box-shadow: 10px 10px 0 0 #000000;
		margin-bottom: 2rem;


	}

	&__nav {}

	&__thumbs {}

	&__thmbSlider {
		transform: translateY(-30px);
		width: 100%;
	}
}

//:::::::::::::::::::::::::::::::::::::::
// .thumbs  - sub layour for gallery thumbs
//:::::::::::::::::::::::::::::::::::::::
.thumbs {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	display: flex;
	justify-content: space-evenly;
	
	@include bp(md) {
		flex-wrap: nowrap;
		justify-content: space-evenly;
	}

	&__slider {
		width: 100%;
		transform: translateY(-10px);

		@include bp(md) {
			width: 50%;
		}
	}

	&__prev {
		@include bp(md) {
			order: -1;
		}
	}

	.swiper-slide img {
		border: 4px solid #322e7a;
	}
}

@supports (-ms-ime-align: auto) {
	.thumbs {
		justify-content: space-around!important;
	}
  }
.swiper-slide img {
	display: block;
}

#thumb-wrapper{
	margin-left: -2px;
}