/////////////////////////////////////////
// Breakpoints
/////////////////////////////////////////
$breakpoints: ( //
	'base': 0px,
	'xs': 576px, // Phones Up
	'sm': 768px, // Tablets small desktops Up
	'md': 992px, // Medium Desktop Up
	'lg': 1200px // Large Desktop Up
);

/////////////////////////////////////////
// MEASURMENTS
/////////////////////////////////////////

//:::::::::::::::::::::::::::::::::::::::
// Site SIZES
//:::::::::::::::::::::::::::::::::::::::
$site_pad-mob: 20px;
$site_pad-desk: 20px;


//:::::::::::::::::::::::::::::::::::::::
// NavBar
//:::::::::::::::::::::::::::::::::::::::
$navHeight-mob: 50px;
$navHeight-desk: 132px;

//:::::::::::::::::::::::::::::::::::::::
// Content Sizes
//:::::::::::::::::::::::::::::::::::::::
$cont_pad-mob: 20px;
$cont_pad-desk: 20px;
$cont-maxW: 1200px;

/////////////////////////////////////////
// Colors
/////////////////////////////////////////
$col-white: #ffffff;
$col-white-off: #E0E0E0;
$col-grey-light: #BDBDBD;
$col-grey-dark: #6B6C6C;
$col-black: #000000;
$col-blue: #055BBC;
$col-green: #008F8F;
$col-brown: #D27462;
$col-red:#FF0000;
$col-purple: #662E91;
$col-pink:#E53CBB;