// @import '../../../../node_modules/perfect-scrollbar/css/perfect-scrollbar.css';

.os-content {
	height: 100% !important;
}

.os-content-glue {
	height: 100% !important;
}

.os-scrollbar.os-scrollbar-vertical {
	width: 20px !important;
	padding: 0 !important;
	border-left: 1px solid $col-grey-dark !important;
}

.os-scrollbar-track {
	background: $col-grey-light !important;

	width: 20px !important;
	z-index: 999;
	border-radius: 0 !important;
}

.os-scrollbar-handle {
	width: 20px !important;
	background: $col-grey-light !important;
	border: 2px solid $col-grey-dark;
	border-top-color: $col-white-off;
	border-left-color: $col-white-off;
	outline: 1px solid $col-grey-dark;
	border-radius: 0 !important;
	right: -1px !important;
}