/////////////////////////////////////////
// .navbar - Navbar object
/////////////////////////////////////////
.navbar {
	width: 100%;
	margin: 0 auto;
	padding: 11px 22px;


	@include bp(md) {}

	&__inner {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;


		@include bp(sm) {
			border-top: 1px solid $col-white-off;
			border-bottom: 1px solid $col-black;
			padding: 10px 0;
		}

		;
	}

	&__bluegrad {
		width: 100%;
		height: 25px;
		background-color: $col-blue;
		background-image: url('../assets/img/grad_blue-lrg.png');
		background-size: 100% 100%;
		margin-bottom: 5px;

		@include bp(sm, max) {
			display: none;

		}

		;
	}

	&__btn {
		width: 35px;
		height: 35px;
		margin: 0 20px;
		border: 2px solid $col-white-off;
		border-right-color: $col-grey-dark;
		border-bottom-color: $col-grey-dark;
		padding: 4px;
		// transition: all 0.1s ease;



		img {
			display: block;
			width: 100%;
		}

		&:hover {
			border: 1px solid $col-white-off;
			border-right-color: $col-grey-dark;
			border-bottom-color: $col-grey-dark;
			padding: 5px;

		}
	}

	&__addr {

		flex-grow: 1;
		background: $col-white;
		border: 2px solid $col-black;
		border-bottom-color: $col-white-off;
		border-right-color: $col-white-off;
		padding: 2px 15px 5px 15px;
		color: $col-black;
		line-height: 1;

		@include bp(sm, max) {
			display: none;
		}
	}

	&__cta {
		width: auto;
		text-align: center;

		margin-top: 5px;

		@include bp(sm) {
			margin-left: 20px;
			margin-top: 2px;
		}

		;
	}
}

/////////////////////////////////////////
// .cookiePol - Cookie Policy tab
/////////////////////////////////////////
.cookiePol {

	position: relative;
	transform: translateY(0%);
	transition: all 0.3s ease;
	background: $col-white;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 10px;
	padding: 10px;
	width: 100%;
	text-align: center;

	&__btn {
		position: absolute;
		bottom: 100%;
		right: 0;
		background: $col-white;
		padding: 5px 5px 5px 5px;
		font-size: 10px;

		@include bp(md) {
			font-size: 12px;
		}
	}

	&__cont {
		max-width: 1000px;
		margin: 0 auto;
	}

	&:hover {
		transform: translateY(-100%);
	}
}

/////////////////////////////////////////
// .btn - Buttons
/////////////////////////////////////////
.btn {
	position: relative;

	&.is-disabled {
		pointer-events: none;
		cursor: default;
	}

	&-box {
		line-height: 1;
		padding: 5px 20px;
		text-align: center;
		margin: 0 5px 5px 0;
		box-shadow: 5px 5px 0 0 $col-black;
		transition: all 0.2s ease;

		&:hover {
			transform: translate(4px, 4px);
			box-shadow: 1px 1px 0 0 $col-black;
		}

		&--img {
			padding: 0;
			line-height: 0;

			img {
				display: block;
				margin: 0 !important;
			}
		}

		&.is-disabled {
			background-color: $col-grey-dark;
		}
	}

	&--blue {
		background-color: $col-blue;
		color: $col-white;

	}

	&--purp {
		background-color: $col-purple;
		color: $col-white;
	}

	&--brown {
		background-color: $col-brown;
		color: $col-white;

	}

	&-slider {
		$height: 50px;
		$shadowW: 3px;
		z-index: 0;


		&::before,
		&::after {
			position: relative;
			z-index: 1;
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: $height/2 0 $height/2 $height/2;
			border-color: transparent transparent transparent $col-brown;
			transition: all 0.2s ease;
		}

		&::after {
			position: absolute;
			z-index: 0;
			top: 0;
			left: -4px;
			border-color: transparent transparent transparent $col-black;
		}

		&--prev {
			transform: rotateZ(180deg);
		}

		&:hover {
			&:before {
				transform: translateX(-3px);
			}
		}

		&:focus {
			outline: 0;
		}
	}
}


.share-buttons {
	@extend %u-stripList;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	li {
		margin: 0 10px;
		width: 30px;
	}
}