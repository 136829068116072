/////////////////////////////////////////
// .site - Main site wrapper and locastions
/////////////////////////////////////////
body {


	@include bp(md) {

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: $site_pad-desk;

		background-color: $col-white-off;

	}

	&.main {
		.js-hideOnMain {
			display: none !important;
		}
	}

	&.donate {
		.js-hideOnDonate {
			display: none !important;
		}
	}

	&.preview {
		.js-hideOnPreview {
			display: none !important;
		}
	}

	&:not(.preview) {
		.js-showOnPreview {
			display: none !important;
		}
	}
}

.site {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;

	min-height: 100%;



	@include bp(md) {
		margin: auto auto;
		width: 100%;
		max-width: $cont-maxW;
		height: 100%;
		min-height: 0;
		max-height: 85vh;

		@include bp(600px, max-height) {
			margin: auto auto;
			height: 60vh;
		}

		&::before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
		}

		&::before {
			background: $col-grey-dark;
			top: 20px;
			left: 20px
		}

		&::after {
			z-index: -2;
			top: 40px;
			left: 40px;
			height: calc(100% - 40px);
			background-image: url('../assets/img/halfTone.png');
		}
	}




	// Add site wide padding to an element
	%siteHorizPad {
		width: 100%;
		padding-left: $site_pad-mob;
		padding-right: $site_pad-mob;

		@include bp(md) {
			padding-left: $site_pad-desk;
			padding-right: $site_pad-desk;
		}
	}

	//:::::::::::::::::::::::::::::::::::::::
	// .site--donate - mofidcations to the donate page
	//:::::::::::::::::::::::::::::::::::::::
	&--donate {
		padding-top: 0 !important;
	}

	//:::::::::::::::::::::::::::::::::::::::
	// MAJOR Z-INDEX
	//:::::::::::::::::::::::::::::::::::::::
	$siteZindex: ( //
		'.site',
		'.site__mast, .site__main, .site__footer',
		'.site__packPreviews',
		'.site__footer',
		'.site__cookie',
		'.site__bar'
	);
	@include createZindex($siteZindex);

	&__scroll {
		width: 100%;
		flex-grow: 1;
		position: relative;
		overflow-x: hidden;
		overflow-y: auto;
		margin-top: 64px;


		@include bp(md) {
			margin-top: 0;


		}
	}

	&__content {
		min-height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		background-color: $col-green;
		background-image: url('../assets/img/content_bkg.gif');
		position: relative;


		@include bp(md) {
			padding-top: 0;

		}
	}

	&__page {
		width: 100%;
	}

	//:::::::::::::::::::::::::::::::::::::::
	// .site__bar - Site nav bar
	//:::::::::::::::::::::::::::::::::::::::
	&__bar {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background-color: $col-grey-light;

		@include bp(md) {
			position: relative;


		}

		>* {
			-webkit-transform: translateZ(0);
		}
	}

	//:::::::::::::::::::::::::::::::::::::::
	// .site__mast - Masthead area
	//:::::::::::::::::::::::::::::::::::::::
	&__mast {
		@extend %siteHorizPad;
		min-height: 50vh;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&.is-disabled {
			display: none;
		}
	}

	//:::::::::::::::::::::::::::::::::::::::
	// .site__main - main content region
	//:::::::::::::::::::::::::::::::::::::::
	&__main {
		@extend %siteHorizPad;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	//:::::::::::::::::::::::::::::::::::::::
	// .site__footer - footer region
	//:::::::::::::::::::::::::::::::::::::::
	&__footer {
		@extend %siteHorizPad;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		width: 100%;
		margin: auto 0 0 0;
		font-size: 0.8rem;
		line-height: 0.8rem;
		padding: 5px 5px 0 5px;
		max-width: 80%;

		@include bp(md) {
			max-width: 100%;
			position: fixed;
			flex-direction: row;
			align-items: center;
			bottom: 5px;
			left: 10px;
		}

		> a {
			display: inline-flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 5px;

			img {
				display: block;
				width: 20px;
				margin-right: 1ch;
			}
		}

		.disclaimer {
			font-family: Arial, Helvetica, sans-serif;
			font-size: 10px;
			line-height: 10px;
			margin-left: 1ch;
			opacity: 0.55;
			color: $col-grey-dark;
			transform: translateY(1px);
			margin-bottom: 5px;

			a {
				text-decoration: underline;
			}
		}
	}

	&__cookie {
		position: fixed;
		top: 100%;
		right: 0;
		width: 100%;
	}

	//:::::::::::::::::::::::::::::::::::::::
	// .site__packPreviews - modal windows for pack previews
	//:::::::::::::::::::::::::::::::::::::::
	&__packPreviews {
		display: none;
		//@extend %siteHorizPad;
		width: 100%;
	}
}