/////////////////////////////////////////
// @include bp() - flexible media queries
/////////////////////////////////////////
@import '../vendor/flexiquery';

/////////////////////////////////////////
// @calcRatio() - Calculate a ratio value as a percentage
//		@param {Number} $width - ratio value for width
//		@param {Number} $height - ratio value for height
//		@param {Number % } $max-width:100% - percentage width of parent
//		@return {Number % } - percentage value (apply to padding-bottom)
/////////////////////////////////////////
@function calcRatio($width, $height, $max-width:100%) {
	@return $height / ($width / $max-width);
}

/////////////////////////////////////////
// @strip-unit() - Fuction to remove uint type from a value
//		@param {Number} $number - Number to remove unit from
//		@return {Number} - Unitless number
/////////////////////////////////////////
@function strip-unit($number) {
	@if type-of($number)=='number'and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}

	@return $number;
}

/////////////////////////////////////////
// @include createZindex() - Create z-index from list
//		@param {$list} $list - List var to make z-index from
//		@return {css} - prints a list of selctors with z-index properties
/////////////////////////////////////////
@mixin createZindex($list) {
	@for $i from 1 through length($list) {
		$el: nth($list, $i);

		@at-root #{$el} {
			z-index: ($i - 1);
			position: relative; // set postion:relative; as a default to enable z-index.
		}
	}
}