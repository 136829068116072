.overlay {
  position: relative;

  min-height: 100%;
  width: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; 
  background-image: url('../assets/img/content_bkg.gif');
  background-color: #008f8f;
  z-index: 10;
  color: white;


  // &__header {
  //   width: 100%;
  //   padding: 20px;
  //   background-color: white;
  //   margin: 0px;

  //   background-image: url('../assets/img/content_bkg.png');
  //   background-color: #008f8f;
  //   z-index: 10;
  //   color: white;
  // }

  &__content {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 20px;

    @include bp(sm) {

      flex-direction: row-reverse;

    }

    @include bp(md) {

      padding: 40px;


    }

    &__column {
      padding: 0 20px 20px 20px;
      display: block;
      overflow: hidden;
      flex: 100%;

      @include bp(sm) {
        flex: 50%;
        padding: 20px;
      }

      .pack {
        padding: 0;
      }
    }
  }
}



.hidden {
  display: none;
}

.blockOverflow {
  overflow: hidden;
}

.swiper-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
  width: auto;
}

.gallery-top {
  height: 80%;
  width: 100%;
}

.gallery-thumbs {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.gallery-thumbs .swiper-slide {
  height: 100%;
  opacity: 0.4;
}

.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.3s both;
  animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.3s both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.secret_folders{

	&__folder{
	
		display : block;

	}

}