@import '../vendor/normalize';

html,
body {
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
}

body {
	position: relative;
	box-sizing: border-box;
	transform-origin: 50% 50%;
	overflow-x: hidden;
	overflow-y: scroll;

	*,
	*:before,
	*:after {
		box-sizing: inherit;
		transform-origin: inherit;
		color: inherit;
		text-decoration: inherit;
	}

	figure,
	blockquote {
		margin: 0;
		padding: 0;
		outline: none;
	}

	svg {
		transform-origin: 0 0 0;
		fill: currentColor;
	}

	img,
	svg,
	object {
		max-width: 100%;
		height: auto;
		outline: none !important;
	}
}